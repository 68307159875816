import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Box, Dialog, Grid, Typography, useTheme} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolder as solidFolderIcon, faExternalLinkAlt as externalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {StyledRowIcon} from '../../folder/FolderListComponent';
import HomeDirectoryModal from '../homeDirectoryModal/HomeDirectoryModal';
import PageSection from '../../common/PageSection';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {getFolderPermissionString} from '../../../util/helpers';
import routes from '../../../util/routes';

export const StyledExternalLinkIcon = styled(FontAwesomeIcon)`
    margin-left: 8px;
`;

// noinspection FunctionNamingConventionJS
function HomeDirectory(props) {

    const theme = useTheme();
    const [showHomeDirectoryModal, setShowHomeDirectoryModal] = useState(false);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const getHomeFolderPermission = function getHomeFolderPermission() {
        return props.folderPermissions.filter(permission =>
            permission.folderId === props.homeFolder?.id && permission.userId === props.id)[0]?.permission;
    };

    const displayHomeFolderPermission = function displayHomeFolderPermissionOnUserEditPage() {
        let homeFolderPermission = getHomeFolderPermission();
        return getFolderPermissionString(homeFolderPermission);
    };

    const handleSubmit = async function handleFolderChange(event, data) {
        await props.handleSubmit(event, data);
        setShowSavedMessage(true);
    };

    return (<>
        <PageSection title='Home Directory' titleVariant='body1'
                     subtitle='Root location where user will land when connecting via SFTP.'
                     subtitleVariant='body2'
                     action={<Grid container alignItems='center'>
                         <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                         <CircularProgressButton label='Change Folder' buttonTextTransform='none' mt={0} color='primary'
                                                 disableElevation size='small' variant='outlined'
                                                 onClick={() => setShowHomeDirectoryModal(true)}/>
                     </Grid>}
                     pb={2} mt={4}/>
        <Grid container item>
            <Dialog open={showHomeDirectoryModal} onClose={() => setShowHomeDirectoryModal(false)}>
                <HomeDirectoryModal handleCloseModal={() => setShowHomeDirectoryModal(false)}
                                    currentHomeFolder={props.homeFolder.id} applyButtonText='Save'
                                    currentHomeFolderPermission={getHomeFolderPermission()}
                                    save={(event, data) => handleSubmit(event, data)}/>
            </Dialog>
            <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap'
                  style={{padding: '.875rem 1rem', backgroundColor: theme.palette.action.hover}}>
                <Grid item style={{flex: '1 1 auto'}}>
                    <Box mr={4}>
                        <Typography variant='body2' style={{wordBreak: 'break-all'}}>
                            <StyledRowIcon size='lg' icon={solidFolderIcon} color={theme.palette.primary.main}/>
                            <Link to={`${routes.folders.path}/${props.homeFolder?.id}`} target='_blank'
                                  style={{color: 'inherit', textDecoration: 'none'}}>
                                {props.homeFolder?.absolutePath}
                                <StyledExternalLinkIcon icon={externalLinkAlt} color={theme.palette.primary.main}/>
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item style={{flex: '0 0 auto'}}>
                    <Typography variant='body2'>{displayHomeFolderPermission()}</Typography>
                </Grid>
            </Grid>
        </Grid>
    </>);
}

HomeDirectory.propTypes = {};

HomeDirectory.defaultProps = {
    folderPermissions: PropTypes.array,
    homeFolder: PropTypes.any,
    id: PropTypes.any,
    handleSubmit: PropTypes.any
};

export default HomeDirectory;
