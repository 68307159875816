import qs from 'querystring';
import axios from 'axios';
import api_routes from '../../util/api_routes';

const {clientid, clientsecret} = window._env_ || {};
const CLIENT_ID = clientid || process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = clientsecret || process.env.REACT_APP_CLIENT_SECRET;

export const authenticationService = {
    login,
    logout,
    clearTokenInfo,
    token: localStorage.getItem('sftpgw'),
    username: localStorage.getItem('sftpgw-user'),
};

async function login(username, password) {
    let data = qs.stringify({
        'grant_type': 'password',
        'username': username,
        'password': password,
        'scope': 'read write',
    });

    let response = await axios.request({
        url: api_routes.auth.endpoint,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: CLIENT_ID,
            password: CLIENT_SECRET,
        },
        data: data
    });

    const {access_token: token, username: name, resetPassword} = response.data;

    if (!resetPassword) {
        localStorage.setItem('sftpgw', token);
        localStorage.setItem('sftpgw-user', name);
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return {token, username: name, resetPassword};
}

async function logout() {
    let token = localStorage.getItem('sftpgw');
    await axios.post(`${api_routes.logout.endpoint}?token=${token}`);
    localStorage.removeItem('sftpgw');
    localStorage.removeItem('sftpgw-user');
}

function clearTokenInfo() {
    localStorage.removeItem('sftpgw');
    localStorage.removeItem('sftpgw-user');
}
