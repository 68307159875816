import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles, useTheme} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles({
    label: {
        fontSize: '.875rem',
        width: '100%',
    }
});

const StyledFormControl = styled(FormControl)`
    width: 100%;
    margin-top: ${props => props.theme.spacing(2)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
`;

// noinspection FunctionNamingConventionJS
function FormControlRadioGroup(props) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <StyledFormControl component='fieldset' theme={theme}>
            <RadioGroup name={props.name} value={props.value} onChange={props.onChange}>
                {props.options.map((option, idx) => !option.hidden &&
                    <FormControlLabel classes={{label: classes.label}} value={option.value} label={option.label}
                                      control={<Radio size='small' checked={option.value === props.value}/>} key={idx}
                                      style={option.style} disabled={option.disabled}/>
                )}
            </RadioGroup>
        </StyledFormControl>
    );
}

FormControlRadioGroup.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
};

FormControlRadioGroup.defaultProps = {
};

export default FormControlRadioGroup;
