import React from 'react';
import {Card, CardContent, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import SettingsCompCloudConnectionsList from './SettingsCompCloudConnectionsList';
import SettingsCompAdminUsers from './SettingsCompAdminUsers';
import routes from '../../util/routes';
import SettingsBackupComponent from "./SettingsBackupComponent";

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    subheader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    card: {
        marginBottom: theme.spacing(4),
    }
}));

// noinspection FunctionNamingConventionJS
export default function SettingsComponent() {
    const classes = useStyles();

    // noinspection MagicNumberJS
    return (
        <div className={classes.root}>
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
                <Grid item>
                    <Typography variant='h5'>{routes.settings.pageTitle}</Typography>
                </Grid>
            </Grid>
            <Typography className={classes.subheader} variant='body1'>Use these settings to manage
                some of the global configuration options for SFTP Gateway. These settings are a subset of
                available configurations. If you would like to set advanced configuration options, visit
                the <Link href={routes.kb.path} target='_blank' rel='noopener noreferrer'>
                    {routes.kb.menuTitle}</Link> or contact us at <Link href='mailto:support@thorntech.com'>
                    support@thorntech.com</Link>.
            </Typography>

            <Card className={classes.card} variant='outlined'>
                <CardContent>
                    <SettingsCompAdminUsers/>
                </CardContent>
            </Card>

            <Card className={classes.card} variant='outlined'>
                <CardContent>
                    <SettingsCompCloudConnectionsList/>
                </CardContent>
            </Card>

            <Card className={classes.card} variant='outlined'>
                <CardContent>
                    <SettingsBackupComponent/>
                </CardContent>
            </Card>
        </div>
    )
}

SettingsComponent.prototype = {};
