import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

// noinspection FunctionNamingConventionJS
function CustomAlert({message, type, onClose}) {

  return (
      <MuiAlert elevation={0} icon={false} onClose={onClose} severity={type}>
        {message}
      </MuiAlert>
  );
}

CustomAlert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  onClose: PropTypes.func,
};

CustomAlert.defaultProps = {
  type: 'success',
};

export default CustomAlert;
