import React from 'react';
import axios from 'axios';
import {useParams} from 'react-router';
import api_routes from '../../util/api_routes';
import useCloudConnection from '../../api/useCloudConnection';
import CloudConnectionUpdateComponent from '../../component/settings/CloudConnectionUpdateComponent';

// noinspection FunctionNamingConventionJS
function CloudConnectionUpdateContainer() {

    const {cloudConnectionId} = useParams();
    const {isLoading, cloudConnection} = useCloudConnection({ params : { cloudConnectionId } });

    const save = async function updateCloudConnection(cloudConnection) {
        return await axios.put(`${api_routes.cloudConnection.endpoint}/${cloudConnectionId}`, cloudConnection);
    };

    return (<CloudConnectionUpdateComponent cloudConnection={cloudConnection} loadingCloudConnection={isLoading}
                                            updateCloudConnection={save} />);
}

CloudConnectionUpdateContainer.propTypes = {};

CloudConnectionUpdateContainer.defaultProps = {};

export default CloudConnectionUpdateContainer;
