import React, {useEffect, useState} from 'react';
import PropTypes, {arrayOf} from 'prop-types';
import toast from 'toasted-notes';
import {useHistory} from 'react-router';
import {makeStyles, useTheme} from '@material-ui/core';
import routes from '../../util/routes';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import ProgressFormCard from '../common/ProgressFormCard';
import {
    StyledBreadcrumbIcon,
    StyledBreadcrumbLink,
    StyledBreadcrumbs
} from '../common/styled';
import FolderEditForm from './FolderEditForm';

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

// noinspection FunctionNamingConventionJS
function FolderUpdateComponent(props) {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();

    const [isBlocking, setIsBlocking] = useState(false);
    const [folder, setFolder] = useState(props.folder);
    const [folderPermissions, setFolderPermissions] = useState(props.folderPermissions);

    // Update the state from updated props.
    useEffect(() => {
        setFolder(props.folder);
        setFolderPermissions(props.folderPermissions);
    }, [props.folder, props.folderPermissions]);

    const handleTextChange = function handleTextChangeOnFolderForm(event) {
        setIsBlocking(true);
        setFolder({...folder, [event.target.name]: event.target.value});
    };

    const handleSubmit = async function handleUpdateUser(event, data) {
        event.preventDefault();
        let response;
        if (data.cloudConnectionId === '-1') {
            response = await props.updateFolder({...data, cloudConnectionId: null});
        } else {
            response = await props.updateFolder(data);
        }
        setFolder(response?.data);
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.goBack();
            }
        } else {
            history.goBack();
        }
    };

    const handleDelete = async function handleFolderDelete() {
        try {
            await props.deleteFolder(folder);
            toast.notify(({onClose}) => <CustomAlert
                message={`Deleted folder ${folder.name}.`} onClose={onClose}/>);
            history.goBack();
        } catch (error) {
            toast.notify(({onClose}) => <CustomAlert
                type='error' message={`Could not delete folder ${folder.name}.`} onClose={onClose}/>);
        }
    };

    return (
        <div className={classes.root}>
            <StyledBreadcrumbs aria-label='back to folders' theme={theme}>
                <StyledBreadcrumbLink color='inherit' onClick={() => history.goBack()}>
                    <StyledBreadcrumbIcon theme={theme}/> Back to folders
                </StyledBreadcrumbLink>
            </StyledBreadcrumbs>
            <ProgressFormCard loadingContent={props.loadingFolder}
                          header={<CustomCardHeader
                              title={routes.updateFolder.pageTitle}
                              titleAlign='center'/>
                          }
                          content={<FolderEditForm folderPermissions={folderPermissions} id={folder.id}
                                                   loadingFolderPermissions={props.loadingFolderPermissions}
                                                   name={folder.name} setName={handleTextChange}
                                                   cloudConnectionId={folder.cloudConnectionId}
                                                   setCloudConnectionId={handleTextChange}
                                                   cloudConnections={props.cloudConnections}
                                                   loadingCloudConnections={props.loadingCloudConnections}
                                                   handleSubmit={handleSubmit} handleCancel={handleCancel}
                                                   deleteFolder={handleDelete} updatePermission={props.updatePermission}/>}
            />
        </div>
    );
}

const FolderPermission = PropTypes.shape({
    userId: PropTypes.any,
    folderId: PropTypes.any,
    username: PropTypes.string,
    permission: PropTypes.string,
});

FolderUpdateComponent.propTypes = {
    folder: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        cloudConnectionId: PropTypes.any,
    }),
    folderPermissions: arrayOf(FolderPermission),
    cloudConnections: PropTypes.array,
    loadingCloudConnections: PropTypes.bool,
    updateFolder: PropTypes.func,
    loadingFolder: PropTypes.bool,
    loadingFolderPermissions: PropTypes.bool,
    deleteFolder: PropTypes.func,
    updatePermission: PropTypes.func,
};

FolderUpdateComponent.defaultProps = {};

export default FolderUpdateComponent;
