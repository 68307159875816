import styled from 'styled-components';
import {IconButton, TableCell, TableHead, TableRow, Select, Button, Breadcrumbs, Link} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

/* --- SFTP GW Tables --- */
export const StyledTableHead = styled(TableHead)`
    background-color: ${props => props.theme.palette.primary.main + '0a'};
`;

export const StyledTableHeadCell = styled(TableCell)`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    color: ${props => props.theme.palette.primary.main};
    border-color: ${props => props.theme.palette.primary.main};
    padding: .875rem 1rem;
`;

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }
  &:nth-of-type(even) {
    background-color: ${props => props.theme.palette.action.hover};
  }
`;

export const StyledTableRowWithForm = styled(StyledTableRow)`
    background-color: ${props => props.theme.palette.secondary.main + '26'}!important;
`;

export const StyledTableCell = styled(TableCell)`
    padding: .875rem 1rem;
    vertical-align: baseline;
`;

export const StyledEditIconButton = styled(IconButton)`
    margin-right: 8px;
`;

export const StyledTableRowInlineEditingButton = styled(Button)`
    min-width: auto; 
    padding: 2px 6px;
`;

/* --- Select without the background --- **/
export const StyledSelect = styled(Select)`
  color: #333;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 3px;
  padding-right: 12px;
`;

/* --- Forms --- */
export const StyledBreadcrumbs = styled(Breadcrumbs)`
    margin-bottom: ${props => props.theme.spacing(3) + 'px'};
    cursor: pointer;
`;

export const StyledBreadcrumbLink = styled(Link)`
    display: flex;
`;

export const StyledBreadcrumbIcon = styled(ArrowBackIosIcon)`
    margin-right: ${props => props.theme.spacing(0.5) + 'px'};
    width: 20px;
    height: 20px;
    align-self: center;
`;

/** Main Page Table **/
export const StyledNavControls = styled.div`
   padding: 16px;
   border: 1px solid;
   border-color: ${props => props.theme.palette.primary.main + '73'};
   background-color: ${props => props.theme.palette.primary.main + '0a'};
   border-top-left-radius: 4px; 
   border-top-right-radius: 4px;
   margin: -1px -1px 0;
   position: relative !important;
`;

export const StyledNavControlsWithoutBackground = styled.div`
   padding: 16px;
   border-bottom: 1px solid;
   border-color: ${props => props.theme.palette.divider};
`;
