import React, {useState} from 'react';
import {
  Grid,
} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import CircularProgressButton from '../common/CircularProgressButton';
import FormControlInput from '../common/FormControlInput';
import CustomAlert from '../common/CustomAlert';
import httpStatus from '../../util/http_status';
import {isEmpty} from '../../util/helpers';

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledFormLogo = styled.div`
  width: 75%;
`;

const StyledFormContent = styled.div`
  display: flex;
  width: 100%;
  color: rgb(148, 146, 146);
  margin: 24px 0px 8px;
  &:before {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 22px 8px 0px;
   }
  &:after {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 0px 8px 22px;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

// noinspection FunctionNamingConventionJS
export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  });

  const handleChange = function updateLoginFormFields(event) {
    setLoginForm({...loginForm, [event.target.name]: event.target.value})
  };

  const handleSignin = async function signInToSFTPGatewayWebApp(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await props.login(loginForm.username, loginForm.password);
    } catch (error) {
      if (!isEmpty(error.response)
          && (error.response.status === httpStatus.badRequest || error.response.status === httpStatus.unAuthorized)) {
        toast.notify(({onClose}) => <CustomAlert type='error'
                                                 message={error.response.data.error_description} onClose={onClose}/>);
      } else {
        toast.notify(({onClose}) =>
            <CustomAlert type='error' message='Something went wrong! Check that the server is running.' onClose={onClose}/>)
      }
      setIsLoading(false);
    }
  };

  return (
      <Grid container justifyContent='center' alignItems='center'
            className='login-form'>
        <StyledFormWrapper className='form-wrapper'>
          <StyledFormLogo className='form-logo'>
            <StyledLogo src='/images/sftpgateway-logo-final.png' alt='SFTPgateway logo'/>
            <StyledLogo src='/images/sftpgateway-logo-final-text.png' alt='SFTPgateway logo text'/>
          </StyledFormLogo>
          <StyledFormContent className='form-content'>
            Sign In
          </StyledFormContent>
          <div>
            <form onSubmit={handleSignin}>
              <FormControlInput width='100' required name='username' value={loginForm.username}
                                label='Username' onChange={handleChange}/>
              <FormControlInput width='100' type='password' required value={loginForm.password}
                                name='password' label='Password' onChange={handleChange}/>
              <CircularProgressButton label='Sign In' variant='contained' size='medium' mt={2}
                                      type='submit' inProgress={isLoading}/>
            </form>
          </div>
        </StyledFormWrapper>
      </Grid>
  )
}

Login.prototype = {
  login: PropTypes.func,
};
