import React, {useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import {Box, Grid, Link, Typography} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';

const StyledBox = styled(Box)`
    display: flex;
    align-items: center;
`;

// noinspection FunctionNamingConventionJS
function TestConnectionResultTable(props) {

    const [connectivity, setConnectivity] = useState(props.connectivity);
    const [testClicked, setTestClicked] = useState(false);
    const [isConnectivityLoading, setIsConnectivityLoading] = useState(false);

    const displayIcon = function dispalyPermissionIconForCloudConnectionTest(result) {
        let resultIcon;
        if (result) {
            resultIcon = <CheckIcon fontSize='small' style={{marginRight: '4px', color: green[500]}}/>
        } else {
            resultIcon = <ClearIcon fontSize='small' style={{marginRight: '4px', color: red['800']}}/>;
        }
        return resultIcon;
    };

    const testConnectivity = async function testConnectivity(event) {
        event.preventDefault();
        setTestClicked(true);
        setIsConnectivityLoading(true);
        const result = await axios.get(`${api_routes.cloudConnection.endpoint}/${props.connectionId}`, {
            params: {updateConnectivity: true}
        });
        setConnectivity(result.data.connectivity);
        setIsConnectivityLoading(false);
    };

    return (
        <>
            <Grid container item md={8} >

                    <CircularProgressButton label='Test Connection' buttonTextTransform='none' mt={2}
                                            inProgress={isConnectivityLoading} color='primary' disableElevation
                                            variant='outlined' fullWidth={false} size='small'
                                            onClick={testConnectivity} />
            </Grid>

            {testClicked &&
                <Grid container item md={8}>
                    <Grid item xs={12} style={{marginTop: '16px'}}>
                        <StyledBox>{displayIcon(connectivity.canList)} <span>Connection</span></StyledBox>
                    </Grid>
                    <Grid item xs={12}>
                    <StyledBox>{displayIcon(connectivity.canRead)} <span>Read Permission</span></StyledBox>
                    </Grid>
                    <Grid item xs={12}>
                    <StyledBox>{displayIcon(connectivity.canWrite)} <span>Write Permission</span></StyledBox>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '16px'}}>
                        <Typography variant='body2' color='textSecondary'>
                            For more information on how to resolve connection issues and configure S3 permissions, please see this&nbsp;
                            <Link href={routes.kbArticle.path} target='_blank' rel='noopener noreferrer'>
                                {routes.kbArticle.menuTitle}</Link>.
                        </Typography>
                    </Grid>
                </Grid>
            }
        </>
    );
}

TestConnectionResultTable.propTypes = {
    connectivity: PropTypes.shape({
        canList: PropTypes.bool,
        canRead: PropTypes.bool,
        canWrite: PropTypes.bool,
    }),
    width: PropTypes.number,
};

TestConnectionResultTable.defaultProps = {
    width: 100,
};

export default TestConnectionResultTable;
