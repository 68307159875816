import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import SSHKeyTable from './formFields/SSHKeyTable';
import HomeDirectory from './formFields/HomeDirectory';
import PasswordSelector from './formFields/PasswordSelector';
import IpAllowListTable from './formFields/IpAllowListTable';
import InlineEditTextField from '../common/InlineEditTextField';
import AdvancedSectionExpansion from '../common/AdvancedSectionExpansion';

// noinspection FunctionNamingConventionJS
function UserEditForm(props) {

  return (
      <form>
        <Grid container justifyContent='center'>
          <Grid container item md={8}>
            <InlineEditTextField name='username' label='Username' labelVariant='body1' value={props.username}
                                 save={(event, data) => props.handleSubmit(event, data)} width='50'
                                 updateMessage='Saved' required/>
          </Grid>
          <Grid container item md={8}>
            <InlineEditTextField name='notes' label='User Notes' labelVariant='body1' value={props.notes}
                                 showCount updateMessage='Saved' multiline
                                 inputProps={{maxLength: 1000}} width='100'
                                 save={(event, data) => props.handleSubmit(event, data)}/>
          </Grid>

          <Grid container item md={8}>
            <HomeDirectory folderPermissions={props.folderPermissions} homeFolder={props.homeFolder}
                           id={props.id} handleSubmit={props.handleSubmit}/>
          </Grid>

          <Grid container item md={8}>
            <SSHKeyTable userId={props.id} publicKeys={props.publicKeys}/>
          </Grid>

          <Grid container item md={8}>
            <AdvancedSectionExpansion mt={3}>
              <Grid item>
                <PasswordSelector usesPassword={props.usesPassword} isPasswordSet={props.isPasswordSet}
                                  handleChange={props.updatePassword}/>
              </Grid>

              <Grid item>
                <IpAllowListTable allowedIps={props.allowedIps} userId={props.id}/>
              </Grid>

            </AdvancedSectionExpansion>
          </Grid>
        </Grid>
      </form>
  );
}

UserEditForm.propTypes = {
  username: PropTypes.string,
  notes: PropTypes.string,
  publicKeys: PropTypes.array,
  password: PropTypes.string,
  allowedIps: PropTypes.array,
  handleSubmit: PropTypes.func,
  homeFolder: PropTypes.any,
  folderPermissions: PropTypes.any,
  isPasswordSet: PropTypes.bool,
  usesPassword: PropTypes.bool,
};

UserEditForm.defaultProps = {};

export default UserEditForm;
