import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import {CardContent} from '@material-ui/core';
import PageLoader from './PageLoader';

// noinspection FunctionNamingConventionJS
function ProgressFormCard(props) {
    return (<Card variant='outlined'>
        {props.header}
        <CardContent style={{position: 'relative'}}>
            {props.loadingContent &&
                <PageLoader style={{marginLeft: '-16px', marginTop: '-16px'}}/>
            }
            {!props.loadingContent &&
                props.content
            }
        </CardContent>
    </Card>);
}

ProgressFormCard.propTypes = {
    loadingContent: PropTypes.bool,
    content: PropTypes.any,
};

ProgressFormCard.defaultProps = {};

export default ProgressFormCard;
