import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
    Container,
    AppBar,
    Button,
    Grid,
    Hidden,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';

import routes from '../../util/routes';
import {setAuthenticated, setCurrentUser} from '../../action';
import {authenticationService} from '../../container/auth/authenticationService';
import ResponsiveNavigation from './ResponsiveNavigation';

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.navbar.main};
`;

const StyledNavLogo = styled.img`
    width: 75px;
    margin-right: .75em;
`;

const StyledNavText = styled.img`
    height: 20px;
`;

const StyledMenuGridItem = styled(Grid)`
  border-left: 1px solid rgb(216, 216, 216);
  margin-left: 16px;
`;

const StyledMenuButton = styled(Button)`
  margin-left: 24px;
  text-transform: none;
  color: ${grey[600]};
`;

const StyledFlexGridItem = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledNavLink = styled(NavLink)`
    padding-bottom: 20px;
    margin: 0px 16px;
    text-decoration: none;
    color: ${props => props.theme.palette.navbar.text};
    font-size: ${props => props.theme.spacing(2)}px;
    font-weight: 700;
    &.active {
      color: ${props => props.theme.palette.primary.main};
      border-bottom: 3px solid ${props => props.theme.palette.secondary.main}; 
    }
    &:hover {
      color: ${props => props.theme.palette.primary.main};
    }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

const StyledChangePasswordLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

// noinspection FunctionNamingConventionJS
function Navigation() {

    const [anchorEl, setAnchorEl] = useState(null);

    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(function getCurrentUserForNavigation(state) {
        return state.currentUser
    });

    const handleClick = function expandNavigationMenu(event) {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = function closeNavigationMenu() {
        setAnchorEl(null);
    };

    const handleSignOut = async function signOut() {
        let user = {username: ''};
        await authenticationService.logout();
        // noinspection NestedFunctionCallJS
        dispatch(setCurrentUser(user));
        // noinspection NestedFunctionCallJS
        dispatch(setAuthenticated(false));
        history.push(routes.home.path);
    };

    return (
        <>
            <StyledAppBar variant='outlined' position='fixed' theme={theme}>
                <Container>
                    <Toolbar component='nav'>
                        <Grid container justifyContent='space-between' direction='row'
                              alignItems='center'>
                            <StyledFlexGridItem item>
                                <Grid container direction='row' justifyContent='space-between'
                                      alignItems='center'>
                                    <Grid item>
                                        <NavLink to={routes.users.path}>
                                            <Grid container alignItems='center' wrap='nowrap'>
                                                <StyledNavLogo
                                                    src='/images/sftpgateway-logo-final.png'
                                                    alt='SFTPgateway logo' className='logo'/>
                                                <StyledNavText
                                                    src='/images/sftpgateway-logo-final-text.png'
                                                    alt='SFTPgateway logo' className='text'/>
                                            </Grid>
                                        </NavLink>
                                    </Grid>
                                </Grid>
                            </StyledFlexGridItem>

                            <Hidden smDown>
                                <StyledFlexGridItem item>
                                    <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                        <Grid item>
                                            <StyledNavLink theme={theme} to={routes.users.path}>
                                                {routes.users.menuTitle}
                                            </StyledNavLink>
                                        </Grid>
                                        <Grid item>
                                            <StyledNavLink theme={theme} to={routes.folders.path}>
                                                {routes.folders.menuTitle}
                                            </StyledNavLink>
                                        </Grid>
                                        <Grid item>
                                            <StyledNavLink theme={theme} to={routes.settings.path}>
                                                {routes.settings.menuTitle}
                                            </StyledNavLink>
                                        </Grid>
                                    </Grid>
                                </StyledFlexGridItem>
                            </Hidden>

                            <Hidden smDown>
                                <StyledMenuGridItem item>
                                    <Grid container justifyContent='flex-end'>
                                        <StyledMenuButton theme={theme}
                                                          aria-controls='user-menu'
                                                          aria-haspopup='true'
                                                          onClick={handleClick}
                                                          endIcon={<ArrowDropDownIcon/>}>
                                            Hi, {currentUser.username}
                                        </StyledMenuButton>
                                        <Menu id='user-menu' anchorEl={anchorEl} keepMounted
                                              open={Boolean(anchorEl)} onClose={handleClose}
                                              getContentAnchorEl={null}
                                              anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'right'
                                              }}
                                              transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right'
                                              }}
                                        >
                                            <StyledChangePasswordLink theme={theme} to={routes.profile.path}>
                                                <StyledMenuItem onClick={handleClose}>
                                                    <StyledListItemIcon>
                                                        <VpnKeyIcon fontSize='small'/></StyledListItemIcon>
                                                    {routes.profile.menuTitle}
                                                </StyledMenuItem>
                                            </StyledChangePasswordLink>
                                            <StyledMenuItem onClick={handleSignOut}>
                                                <StyledListItemIcon style={{minWidth: '32px'}}>
                                                    <ExitToAppIcon fontSize='small'/></StyledListItemIcon>
                                                Log Out
                                            </StyledMenuItem>
                                        </Menu>
                                    </Grid>
                                </StyledMenuGridItem>
                            </Hidden>

                            <Hidden mdUp>
                                <StyledFlexGridItem item>
                                    <Grid container justifyContent='flex-end'>
                                        <ResponsiveNavigation currentUser={currentUser} signOut={handleSignOut} />
                                    </Grid>
                                </StyledFlexGridItem>
                            </Hidden>

                        </Grid>
                    </Toolbar>
                </Container>
            </StyledAppBar>
            <Toolbar>{/* content, do not remove, this is important for the layout */}</Toolbar>
        </>
    );
}

export default Navigation;
