import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormHelperText, Grid, Typography} from '@material-ui/core';
import {isEmpty} from "../../../../util/helpers";

// noinspection FunctionNamingConventionJS
function UploadFile(props) {
  const {pubKey, setPubKey} = props;
  const [uploadFile, setUploadFile] = useState('');

  const handleKeyUpload = function handleKeyUpload(file) {
    if (file) {
      let reader = new FileReader();
      reader.onload = function readOnLoad(e) {
        let contents = e.target.result;
        setPubKey({
          ...pubKey,
          ...(isEmpty(pubKey.name) && {name: file.name}),
          value: Buffer.from(contents).toString("utf-8")
        });
        setUploadFile(file.name);
      };
      reader.readAsBinaryString(file);
    } else {
      alert({msg: 'Failed to upload file.', type: 'error'});
    }
  };

  const handleUpload = function handleUpload(ev) {
    const file = ev.target.files[0];
    handleKeyUpload(file);
  };

  return <Grid item container alignItems={"center"} spacing={2}>
    <Grid item>
      <Typography variant='body2'>
        Already have a key?
      </Typography>
    </Grid>
    <Grid item>
      <input
          id='upload-file'
          type='file'
          style={{display: 'none'}}
          onChange={handleUpload}
      />
      <label htmlFor='upload-file'>
        <Button disableElevation style={{textTransform: 'none'}}
            color="primary"
            variant="outlined"
            component='span'
            size="small"
        >Upload Public Key</Button>
      </label>
      <FormHelperText>
        {uploadFile}
      </FormHelperText>
    </Grid>
  </Grid>

}

UploadFile.propTypes = {
  pubKey: PropTypes.object,
  setPubKey: PropTypes.func,
};

export default UploadFile;