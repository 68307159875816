import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgressButton from './CircularProgressButton';

// noinspection FunctionNamingConventionJS
function ConfirmDialog(props) {

    const { title, children, open, setOpen, onConfirm } = props;

    const handleCloseModal = function handleCloseConfimationModal() {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            aria-labelledby='confirm-dialog'
        >
            <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <CircularProgressButton label='Yes' buttonTextTransform='none' variant='outlined' size='small'
                                        onClick={() => {
                                            handleCloseModal();
                                            onConfirm();
                                        }}
                />
                <CircularProgressButton label='No' buttonTextTransform='none' variant='text' color='default'
                                        onClick={handleCloseModal} size='small'
                />
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func,
};

ConfirmDialog.defaultProps = {
    open: false,
};

export default ConfirmDialog;
