import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy as copyIcon} from '@fortawesome/free-regular-svg-icons';
import {Button, Grid, IconButton, InputAdornment, useTheme} from '@material-ui/core';
import CustomAlert from '../common/CustomAlert';
import PageSection from '../common/PageSection';
import FormControlInput from '../common/FormControlInput';
import FormControlRadioGroup from '../common/FormControlRadioGroup';
import CircularProgressButton from '../common/CircularProgressButton';
import TestConnectionResultTable from './TestConnectionResultTable';
import {isEmpty} from '../../util/helpers';

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)}px;
`;

// noinspection FunctionNamingConventionJS
function AzureCloudConnectionForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        await props.handleSubmit(event);
    };

    const copyUrl = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.notify(({onClose}) => <CustomAlert type='success' message='Url copied!' onClose={onClose}/>);
        });
    };
    //noinspection MagicNumberJS, ConditionalExpressionJS
    return (<form onSubmit={handleSubmit} noValidate>
        <Grid container justifyContent='center'>
            <Grid container item md={8}>
                <FormControlInput value={props.name} width='100' label='Connection Name' name='name'
                                  placeholder='Enter unique name' onChange={props.setName} required
                                  helperText='Name used to identify this cloud connection.'
                                  errorMessage={props.errors.name}
                />
            </Grid>
            <Grid container item md={8}>
                <FormControlInput value={props.notes} width='100' label='Cloud Connection Notes'
                                  name='notes' multiline onChange={props.setNotes}
                                  inputProps={{maxLength: 1000}} showCount
                                  placeholder='Enter notes about the cloud connection (optional)'
                                  errorMessage={props.errors.notes}
                />
            </Grid>
            <Grid container item md={8}>
                <FormControlInput value={props.basePrefix} width='100' label='Container Resource URI' name='basePrefix'
                                  required
                                  autoComplete="off"
                                  onChange={props.setBasePrefix} placeholder='Enter URI for Azure Container'
                                  helperText='Ex: https://storage-account-name.blob.core.windows.net/container-name'
                                  errorMessage={props.errors.basePrefix}
                                  endAdornment={<InputAdornment position='end'>
                                      <IconButton onClick={function handleCopyUrl(event) {
                                          event.stopPropagation()
                                          copyUrl(props.basePrefix)
                                      }}>
                                          <FontAwesomeIcon icon={copyIcon} color={theme.palette.primary.main}
                                                           size={"xs"}/>
                                      </IconButton>
                                  </InputAdornment>}
                />
            </Grid>
            <Grid container item md={8}>

                <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                             subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'/>
                <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                       onChange={props.setInstanceCredentials}
                                       options={[{
                                           value: true, label: 'Use instance identity'
                                       }, {
                                           value: false, label: 'Use unique credentials'
                                       }]}
                />
            </Grid>
            <Grid container item md={8}>
                <div style={{width: '100%', paddingLeft: '28px', marginTop: '-16px'}}>
                <FormControlInput value={props.connectionString} width='100' label='Connection String'
                                  helpLink={{
                                      href: 'http://www.sftpgateway.com/azure-connection-string.html',
                                      target: '_blank',
                                      text: 'Where do I find the connection string?'
                                  }}
                                  autoComplete="off"
                                  name='connectionString' onChange={props.setConnectionString}
                                  placeholder={`${props.connectionStringSet ? '**********' : 'Enter connection string'}`}
                                  helperText='This is the default connection string used for this cloud connection.'
                                  errorMessage={props.errors.connectionString}
                                  disabled={props.useInstanceCredentials} required={!props.useInstanceCredentials}
                />
                </div>
            </Grid>
            {!isEmpty(props.connectivity) &&
            <TestConnectionResultTable connectivity={props.connectivity} connectionId={props.id}/>
            }
            <Grid container item justifyContent='flex-end' md={8}>
                <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                        label='Save' mt={2} inProgress={loadingSubmit}/>
                <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                              theme={theme}>Cancel</StyledButton>
            </Grid>
        </Grid>
    </form>);
}

AzureCloudConnectionForm.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    basePrefix: PropTypes.string,
    setBasePrefix: PropTypes.func,
    accountName: PropTypes.string,
    setAccountName: PropTypes.func,
    connectionString: PropTypes.string,
    setConnectionString: PropTypes.func,
    region: PropTypes.string,
    setRegion: PropTypes.func,
    useInstanceCredentials: PropTypes.bool,
    setInstanceCredentials: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
    accessSecretSet: PropTypes.bool,
    connectivity: PropTypes.object,
};

AzureCloudConnectionForm.defaultProps = {
    accessSecretSet: false,
};

export default AzureCloudConnectionForm;
