import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@material-ui/core';
import UserFolderPermissionsTable from './UserFolderPermissionsTable';
import {isEmpty} from '../../util/helpers';
import InlineEditTextField from '../common/InlineEditTextField';
import InlineEditSelectField from '../common/InlineEditSelectField';

// noinspection FunctionNamingConventionJS
function FolderEditForm(props) {

    const getOptions = function getOptions() {
        let tempOptions = [];
        if (props.id !== 0) {
            tempOptions = [{key: '-1', value: '-1', displayValue: 'Inherited'}];
        }
        return tempOptions.concat(props.cloudConnections.map(cloudConnection =>
            ({key: cloudConnection.id, value: cloudConnection.id, displayValue: cloudConnection.name})));
    };

    // noinspection ConditionalExpressionJS
    return (
        <form>
            <Grid container justifyContent='center'>
                <Grid container item md={8}>
                    <InlineEditTextField name='name' label='Folder Name' labelVariant='body1' value={props.name}
                                         updateMessage='Saved' required
                                         save={(event, data) => props.handleSubmit(event, data)}/>
                </Grid>
                <Grid container item md={8}>
                    <UserFolderPermissionsTable
                        folderId={props.id}
                        userFolderPermissions={props.folderPermissions}
                        updatePermission={props.updatePermission}
                        loadingFolderPermissions={props.loadingFolderPermissions}/>
                </Grid>
                <Grid container item md={8}>
                    <Grid item>
                        <Box mt={3}>
                            <InlineEditSelectField name='cloudConnectionId' label='Cloud Connection' width='100'
                                                   helperText='This is the cloud connection that will be used by this folder.'
                                                   labelVariant='body1' updateMessage='Saved'
                                                   value={isEmpty(props.cloudConnectionId?.toString()) ? '-1' : props.cloudConnectionId?.toString()}
                                                   save={(event, data) => props.handleSubmit(event, data)}
                                                   options={getOptions()}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

const CloudConnection = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FolderEditForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    name: PropTypes.string,
    cloudConnectionId: PropTypes.any,
    setName: PropTypes.func,
    setCloudConnectionId: PropTypes.func,
    cloudConnections: PropTypes.arrayOf(CloudConnection),
    folderPermissions: PropTypes.array,
    deleteFolder: PropTypes.func,
    updatePermission: PropTypes.func,
    loadingFolderPermissions: PropTypes.bool,
    id: PropTypes.any,
};

FolderEditForm.defaultProps = {
    cloudConnections: [],
    cloudConnectionId: '',
};

export default FolderEditForm;
