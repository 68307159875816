import React, {useState} from 'react';
import _ from 'lodash';
import toast from 'toasted-notes';
import {useHistory} from 'react-router-dom';
import {Card, CardContent, makeStyles, useTheme,} from '@material-ui/core';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import UserCreateForm from './UserCreateForm';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject} from '../../util/errorHandler';
import useFolder from '../../api/useFolder';

// noinspection AnonymousFunctionJS, MagicNumberJS
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  }
}));

const initUser = {
  username: '',
  notes: '',
  homeDirectory: '1', // default or custom home dir choice
  homeFolderId: null, // id of the home dir
  permission: 'READ_WRITE',   // permission for the home dir ('READ_WRITE' by default)
  publicKeys: [],
  password: '',
  allowedIps: [],
};

// noinspection FunctionNamingConventionJS
function UserCreateComponent(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const {saveUser} = {...props};

  const [isBlocking, setIsBlocking] = useState(false);
  const [user, setUser] = useState(initUser);
  const [errors, setErrors] = useState({});
  const {folder: homeFolder} = useFolder({ params: {folderId: user.homeFolderId }});

  const handleTextChange = function handleTextChangeOnUserForm(event) {
    setIsBlocking(true);
    setUser({...user, [event.target.name]: event.target.value});
  };

  const setHomeFolderAndPermission = function setHomeFolderAndPermission({homeFolderId, permission}) {
    setUser({...user, homeFolderId, permission});
  };

  const addPublicKey = function addPublicKeyToSSHKeysTableOnUserForm(key) {
    setIsBlocking(true);
    let tempPublicKeys = user.publicKeys;
    tempPublicKeys = tempPublicKeys.concat(key);
    setUser({...user, publicKeys: tempPublicKeys});
  };

  const updatePublicKey = async function handleUpdatePublicKeyFromEditModal(index, key) {
      let tempPublicKeys = user.publicKeys;
      tempPublicKeys[index].enabled = key.enabled;
      tempPublicKeys[index].name = key.name;
      setUser({...user, publicKeys: tempPublicKeys});
  };

  const removePublicKey = function deletePublicKeyFromSSHKeysTableOnUserForm(index) {
    setIsBlocking(true);
    let tempPublicKeys = user.publicKeys;
    _.remove(tempPublicKeys, function removeKey(publicKey, idx) {
      return idx === index;
    });
    setUser({...user, publicKeys: tempPublicKeys});
  };

  const addIpAddress = function addIpAddressToIpAddressAllowlistTableOnUserForm(address) {
    setIsBlocking(true);
    let tempIpAddresses = user.allowedIps;
    tempIpAddresses = tempIpAddresses.concat(address);
    setUser({...user, allowedIps: tempIpAddresses});
  };

  const removeIpAddress = function deleteIpAddressFromIpAddressAllowlistTableOnUserForm(index) {
    setIsBlocking(true);
    let tempIpAddresses = user.allowedIps;
    _.remove(tempIpAddresses, function removeIp(ipAddress, idx) {
      return idx === index;
    });
    setUser({...user, allowedIps: tempIpAddresses});
  };

  const handleSubmit = async function handleSaveUser(event) {
    event.preventDefault();
    try {
      let tempUser;
      if (user.homeDirectory === '1') {
        tempUser = {...user, homeFolderId: null, permission: 'READ_WRITE'};
      } else {
        tempUser = user;
      }
      await saveUser(tempUser);
      toast.notify(({onClose}) => <CustomAlert message={'New user added!'} onClose={onClose}/>);
      history.push(routes.users.path);
    } catch (error) {
      if (!isEmpty(error.response) && (error.response.status === httpStatus.conflict)) {
        setErrors({username: 'Duplicate entry.'});
      } else {
        let tempErrors = convertErrorsToObject(error.response);
        setErrors(tempErrors);
      }
    }
  };

  const handleCancel = function handleCancel() {
    if (isBlocking) {
      if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
        setIsBlocking(false);
        history.push(routes.users.path);
      }
    } else {
      history.push(routes.users.path);
    }
  };

  return (
      <div className={classes.root}>
        <StyledBreadcrumbs aria-label='back to users' theme={theme}>
          <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.users.path)}>
            <StyledBreadcrumbIcon theme={theme} />
            Back to users
          </StyledBreadcrumbLink>
        </StyledBreadcrumbs>
        <Card variant='outlined'>
          <CustomCardHeader
              title={routes.createUser.pageTitle}
              titleAlign='center'
          />
          <CardContent>
            <UserCreateForm
                username={user.username} setUsername={handleTextChange}
                notes={user.notes} setNotes={handleTextChange}
                homeFolder={homeFolder} homeFolderPermission={user.permission}
                homeDirectory={user.homeDirectory} setHomeDirectory={handleTextChange}
                setHomeFolderAndPermission={setHomeFolderAndPermission}
                publicKeys={user.publicKeys} addPublicKey={addPublicKey}
                removePublicKey={removePublicKey} updatePublicKey={updatePublicKey}
                password={user.password} setPassword={handleTextChange}
                allowedIps={user.allowedIps} addIpAddress={addIpAddress} removeIpAddress={removeIpAddress}
                handleSubmit={handleSubmit} handleCancel={handleCancel}
                errors={errors}
            />
          </CardContent>
        </Card>
      </div>
  );
}

export default UserCreateComponent;
