import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import {Card, CardContent, makeStyles, useTheme} from '@material-ui/core';
import FolderForm from './FolderForm';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import {
    StyledBreadcrumbIcon,
    StyledBreadcrumbLink,
    StyledBreadcrumbs
} from '../common/styled';
import routes from '../../util/routes';
import {convertErrorsToObject} from '../../util/errorHandler';

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

// noinspection FunctionNamingConventionJS
function FolderCreateComponent(props) {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const [isBlocking, setIsBlocking] = useState(false);
    const [errors, setErrors] = useState({});
    const [folder, setFolder] = useState({
        name: '',
        cloudConnectionId: '-1', // Doing this to play nice with select component, the value is actually null
    });

    const handleTextChange = function handleTextChangeOnFolderForm(event) {
        setIsBlocking(true);
        setFolder({...folder, [event.target.name]: event.target.value});
    };

    const handleSubmit = async function createNewFolder(event) {
        event.preventDefault();
        try {
            if (folder.cloudConnectionId === '-1') {
                await props.createFolder({...folder, cloudConnectionId: null});
            } else {
                await props.createFolder(folder);
            }
            toast.notify(({onClose}) => <CustomAlert message={`Created folder ${folder.name}.`} onClose={onClose}/>);
            history.goBack();
        } catch (error) {
            setErrors(convertErrorsToObject(error.response));
        }
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.goBack();
            }
        } else {
            history.goBack();
        }
    };

    return (<div className={classes.root}>
        <StyledBreadcrumbs aria-label='back to folders' theme={theme}>
            <StyledBreadcrumbLink color='inherit' onClick={() => history.goBack()}>
                <StyledBreadcrumbIcon theme={theme} /> Back to folders
            </StyledBreadcrumbLink>
        </StyledBreadcrumbs>
        <Card variant='outlined'>
            <CustomCardHeader
                title={routes.createFolder.pageTitle}
                titleAlign='center'
            />
            <CardContent>
                <FolderForm name={folder.name} setName={handleTextChange}
                            cloudConnectionId={folder.cloudConnectionId} setCloudConnectionId={handleTextChange}
                            cloudConnections={props.cloudConnections}
                            handleSubmit={handleSubmit} handleCancel={handleCancel} errors={errors}
                />
            </CardContent>
        </Card>
    </div>);
}

FolderCreateComponent.propTypes = {
    cloudConnections: PropTypes.array,
    createFolder: PropTypes.func,
};

FolderCreateComponent.defaultProps = {};

export default FolderCreateComponent;
