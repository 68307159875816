import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledGridContainer = styled(Grid)`
  height: 100%;
`;

// noinspection FunctionNamingConventionJS
function PageLoader(props) {
  return (<StyledGridContainer container justifyContent='center' alignItems='center' style={props.style}>
    <CircularProgress color='secondary' size={props.size}/>
  </StyledGridContainer>);
}

PageLoader.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
};

PageLoader.defaultProps = {
  size: 40,
};

export default PageLoader;
