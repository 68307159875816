import React from 'react';
import axios from 'axios';
import api_routes from '../../util/api_routes';
import UserCreateComponent from '../../component/user/UserCreateComponent';

// noinspection FunctionNamingConventionJS
function UserCreateContainer() {

  const saveUser = async function saveUser(user) {
      return await axios.post(api_routes.user.endpoint, user);
  };

  return <UserCreateComponent saveUser={saveUser}/>;
}

export default UserCreateContainer;
