import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import PropTypes from 'prop-types';
import {Card, CardContent, Grid, makeStyles, Typography} from '@material-ui/core';
import toast from 'toasted-notes';

import CustomAlert from '../common/CustomAlert';
import FormControlInput from '../common/FormControlInput';
import CircularProgressButton from '../common/CircularProgressButton';
import routes from '../../util/routes';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject} from '../../util/errorHandler';
import {setAuthenticated, setCurrentUser} from '../../action';

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    overflow: 'inherit',
  },
}));

// noinspection FunctionNamingConventionJS
function ChangePasswordComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [confirmPasswordError, setConfirmPasswordError] = useState(
      {error: false, errorMessage: ''});
  const [currentPasswordError, setCurrentPasswordError] = useState(
      {error: false, errorMessage: ''});
  const [newPasswordError, setNewPasswordError] = useState(
      {error: false, errorMessage: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordForm, setChangePasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleChange = function updateChangePasswordFormFields(event) {
    setChangePasswordForm(
        {...changePasswordForm, [event.target.name]: event.target.value})
  };

  const handleCancel = function handleResettingChangePasswordForm() {
    history.goBack();
  };

  const handleSubmit = async function submitChangePassword(event) {
    event.preventDefault();
    if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
      setConfirmPasswordError({error: false, errorMessage: ''});
      setIsLoading(true);
      try {
        await props.changePassword({
          currentPassword: changePasswordForm.currentPassword,
          newPassword: changePasswordForm.newPassword
        });
        toast.notify(
            ({onClose}) => <CustomAlert message='Success! Please login with your new password!' onClose={onClose}/>);
        let user = {username: ''};
        // noinspection NestedFunctionCallJS
        dispatch(setCurrentUser(user));
        // noinspection NestedFunctionCallJS
        dispatch(setAuthenticated(false));
        history.push(routes.home.path);
      } catch (error) {
        setIsLoading(false);
        if (error.response.status === httpStatus.unAuthorized) {
          setCurrentPasswordError({error: true, errorMessage: 'Wrong password. Try again.'})
        } else if (error.response.status === httpStatus.badRequest) {
          setCurrentPasswordError({error: false, errorMessage: ''});
          let tempErrors = convertErrorsToObject(error.response);
          setNewPasswordError({error: true, errorMessage: tempErrors?.password});
        }
      }
    } else {
      setCurrentPasswordError({error: false, errorMessage: ''});
      setNewPasswordError({error: false, errorMessage: ''});
      setConfirmPasswordError({error: true, errorMessage: 'New passwords don\'t match'});
    }
  };

  return <div className={classes.root}>
    <Grid container>
      <Grid item>
        <Typography variant='h5'>{routes.profile.menuTitle}</Typography>
      </Grid>
    </Grid>
    <Card className={classes.card} variant='outlined'>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent='center'>
            <Grid container item xs={8}>
              <FormControlInput width='100' type='password' required name='currentPassword'
                                value={changePasswordForm.currentPassword} label='Current Password'
                                onChange={handleChange} error={currentPasswordError.error}
                                errorMessage={currentPasswordError.errorMessage}/>
            </Grid>
            <Grid container item xs={8}>
              <FormControlInput width='100' type='password' required name='newPassword'
                                label='New Password' value={changePasswordForm.newPassword}
                                onChange={handleChange} error={newPasswordError.error}
                                tooltip="At least one capital letter, one lower case letter, one number,
                                              and one special character"
                                errorMessage={newPasswordError.errorMessage}/>
            </Grid>
            <Grid container item xs={8}>
              <FormControlInput error={confirmPasswordError.error}
                                errorMessage={confirmPasswordError.errorMessage}
                                width='100' type='password' required
                                name='confirmNewPassword' value={changePasswordForm.confirmNewPassword}
                                label='Confirm New Password'
                                onChange={handleChange}/>
            </Grid>
            <Grid container item xs={8} justifyContent='flex-end'>
              <CircularProgressButton label='Update' variant='contained' size='small' mt={2}
                                      type='submit' inProgress={isLoading}/>
              <CircularProgressButton label='Cancel' variant='text' color='default' size='small'
                                      ml={1} mt={2} onClick={handleCancel} type='reset'/>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  </div>
}

export default ChangePasswordComponent;

ChangePasswordComponent.prototype = {
  changePassword: PropTypes.func,
};
