import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    ListItemIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme
} from '@material-ui/core';
import {faFolder as solidFolderIcon} from '@fortawesome/free-solid-svg-icons';
import {StyledRowIcon} from './FolderListComponent';
import {isEmpty} from '../../util/helpers';
import awsIcon from "@iconify-icons/mdi/aws";
import azureIcon from "@iconify-icons/mdi/microsoft-azure";
import {Icon} from "@iconify/react";

const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.palette.action.hover};
  }
`;

const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

// noinspection FunctionNamingConventionJS
function FoldersTable({folder, goToParentFolder, openFolder}) {

    const theme = useTheme();

    const providerIcon = (connection) => {
        switch (connection.cloudProvider) {
            case "aws":
                return awsIcon;
            case "azure":
                return azureIcon;
            default:
                return null;
        }
    }

    return (
        <TableContainer>
            <Table aria-label='folders-table'>
                {folder.id !== 0 &&
                <TableHead>
                    <StyledTableRow theme={theme} key={-1}>
                        <TableCell theme={theme} style={{fontWeight: '900'}}
                                   onClick={() => goToParentFolder(folder)}>
                            ..
                            </TableCell>
                        </StyledTableRow>
                    </TableHead>
                }
                <TableBody>
                    {!isEmpty(folder.folders) && folder.folders
                        .map((item, idx) =>
                            <StyledTableRow theme={theme} key={item.id}>
                                <TableCell style={idx === folder.folders.length - 1 ? {borderBottom: 'none'} : {}}
                                           onClick={() => openFolder(item)}>
                                    <StyledRowIcon size='lg' icon={solidFolderIcon}
                                                   color={theme.palette.primary.main}/>
                                    {item.name}
                                    {!isEmpty(item.cloudConnection) &&
                                    <StyledTableIcon>
                                        <Icon width={22} icon={providerIcon(item.cloudConnection)}/>
                                    </StyledTableIcon>}

                                </TableCell>
                            </StyledTableRow>
                        )}
                    {(!isEmpty(folder.folders) && folder.folders.length === 0) &&
                    <StyledTableRow theme={theme}>
                        <TableCell colSpan={2} align='center' theme={theme} style={{borderBottom: 'none'}}>
                            No folders to display
                        </TableCell>
                    </StyledTableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const Folder = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FoldersTable.propTypes = {
    folder: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        absolutePath: PropTypes.string,
        ltreePath: PropTypes.string,
        folders: PropTypes.arrayOf(Folder),
    }),
    goToParentFolder: PropTypes.func,
    openFolder: PropTypes.func,
};

FoldersTable.defaultProps = {};

export default FoldersTable;
