import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import {grey} from '@material-ui/core/colors';
import {Divider, List, ListSubheader, Menu, MenuItem, ListItemText, Typography} from '@material-ui/core';
import routes from '../../util/routes';

const StyledMenu = withStyles({
    paper: {
        border: `1px solid ${grey[300]}`,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            fontSize: '0.85rem',
        },
        '&.active': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                borderBottom: `3px solid ${theme.palette.secondary.main}`,
                width: 'fit-content',
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
}))(MenuItem);

const StyledMenuIcon = styled(MenuIcon)`
    cursor: pointer;
`;

// noinspection FunctionNamingConventionJS
function ResponsiveNavigation({currentUser, signOut}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = (event) => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);

    return (<>
        <StyledMenuIcon color='primary' onClick={openMenu} />
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            <List component='nav'
                  subheader={
                      <ListSubheader style={{padding: '8px 16px'}} component='div' id='nav-subheader'>
                          <Typography variant='body2'>Hi, {currentUser.username}</Typography>
                      </ListSubheader>
                  }
            >
            </List>
            <Divider />
            <List component='nav' aria-labelledby='nav-subheader'
            >
                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.users.path}>
                    <ListItemText primary={routes.users.menuTitle} />
                </StyledMenuItem>
                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.folders.path}>
                    <ListItemText primary={routes.folders.menuTitle} />
                </StyledMenuItem>
                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.settings.path}>
                    <ListItemText primary={routes.settings.menuTitle} />
                </StyledMenuItem>
            </List>
            <Divider />
            <List component='nav'>
                <StyledMenuItem component={NavLink} onClick={closeMenu} to={routes.profile.path}>
                    <ListItemText primary={routes.profile.menuTitle} />
                </StyledMenuItem>
                <StyledMenuItem onClick={signOut}>
                    <ListItemText primary='Log Out' />
                </StyledMenuItem>
            </List>
        </StyledMenu>

    </>);
}

ResponsiveNavigation.propTypes = {
    currentUser: PropTypes.object,
};

ResponsiveNavigation.defaultProps = {};

export default ResponsiveNavigation;
