import React, {useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import {Publish} from '@material-ui/icons';
import {FormHelperText, Grid, Input, InputAdornment, Typography} from '@material-ui/core';
import api_routes from '../../util/api_routes';
import httpStatus from '../../util/http_status';
import PageSection from '../common/PageSection';
import CircularProgressButton from '../common/CircularProgressButton';

const StyledMessageItem = styled(Typography)`
    margin-left: 1.25rem;
`;
const StyledHelperText = styled(FormHelperText)`
`;
const StyledInput = styled(Input)`
  background-color: ${props => {
    let color;
    if (props.importcomplete === 'true') {
        color = `rgba(0, 255, 0, 0.15)`;
    } else {
        color = 'rgba(0,0,0,0.02)';
    }
    return color;
}};
  color: ${props => {
    let color;
    if (props.importcomplete === ' true') {
        color = `rgb(11, 121, 11)`;
    } else {
        color = '#333';
    }
    return color;
}};
  width: 13rem;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  padding-left: 12px;
  border-radius: 5px;
  padding-right: 12px;
  &:hover{
    cursor: pointer;
 }
  input[type=file]::-webkit-file-upload-button {
    display: none;
  }
`;

function SettingsBackupComponent() {
    const [loadingExportArtifact, setLoadingExportArtifact] = useState(false);
    const [loadingImportArtifact, setLoadingImportArtifact] = useState(false);
    const [importStatus, setImportStatus] = useState({
        complete: false,
        message: []
    });
    const [selectedFile, setSelectedFile] = useState({
        file: null,
        name: ''
    });

    const downloadBackupArtifact = (filename, content) => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + content);
        element.setAttribute('download', filename + '.yaml');
        element.style.display = 'none';
        window.document.body.appendChild(element);
        element.click();
        window.document.body.removeChild(element);
    };

    const handleExportArtifact = async () => {
        const datetime = moment().format("YYYY-MM-DD-HH-mm-ss")
        const artifact = "sftpgw-backup-" + datetime;
        setLoadingExportArtifact(true)
        try {
            const response = await axios.get(
                `${api_routes.backup.endpoint}`
            );
            if (response.status === httpStatus.ok) {
                downloadBackupArtifact(artifact, response.data)
            }
        } catch (error) {
            alert("Export failed due to: " + error);
            console.error(error);
        } finally {
            setLoadingExportArtifact(false);
        }
    };

    const handleImportArtifact = () => {
        let reader = new FileReader();
        reader.onload = async function readOnLoad(e) {
            setLoadingImportArtifact(true);
            let contents = e.target.result;
            try {
                const response = await axios.post(`${api_routes.backup.endpoint}`, Buffer.from(contents).toString("utf-8"))
                if (response.status === httpStatus.ok) {
                    setImportStatus({
                        complete: true,
                        message: response.data
                    });

                }
            } catch (error) {
                alert("Import failed due to: " + error);
                console.error(error);
            } finally {
                setLoadingImportArtifact(false);
            }

        };
        reader.readAsBinaryString(selectedFile.file);
    };

    const handleSelectFile = (event) => {
        let inputFile = event.target.files[0];
        setImportStatus({
            complete: false,
            message: []
        });
        setSelectedFile({
            file: inputFile,
            name: inputFile?.name
        });
    };

    return (<>
        <PageSection title='Backup and Recovery'
                     subtitle='The following can be used to export and import backup and recovery artifacts.'
        />

        <Grid container direction={"column"} alignItems={"flex-start"}>
            <Grid item>
                <CircularProgressButton buttonTextTransform='none' label='Export' mt={0}
                                        size='small' inProgress={loadingExportArtifact}
                                        onClick={handleExportArtifact}
                />
            </Grid>
            <Grid item>
                <Grid container item style={{marginTop: '1rem'}}>
                    <StyledInput type='file' importcomplete={importStatus.complete.toString()} name='backupupload'
                                 inputProps={{accept: '.yaml,.yml'}}
                                 onChange={handleSelectFile}
                                 startAdornment={
                                     <InputAdornment position="start">
                                         <Publish/>
                                     </InputAdornment>
                                 }
                    />
                    <CircularProgressButton buttonTextTransform='none' mt={0} label='Import'
                                            size='small' inProgress={loadingImportArtifact}
                                            disabled={!selectedFile.file}
                                            onClick={handleImportArtifact}/>
                </Grid>
                {importStatus.complete
                    ? <StyledHelperText id='import-helper-text'>Import Complete</StyledHelperText>
                    : <FormHelperText> Imported files must have the extension ".yaml" or ".yml" </FormHelperText>
                }
            </Grid>
        </Grid>

        {importStatus.complete &&
        <Grid container direction={"column"}>
            <Typography variant={"h6"}>Import Status Messages:</Typography>
            {importStatus.message.map(
                (str, idx) => (<StyledMessageItem key={idx} variant={"body1"}>{str}</StyledMessageItem>))
            }
        </Grid>
        }
    </>);
}

export default SettingsBackupComponent;
