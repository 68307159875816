import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Dialog, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import CircularProgressButton from './common/CircularProgressButton';
import {isEmptyObject} from '../util/helpers';
import routes from '../util/routes';

// noinspection FunctionNamingConventionJS
function SystemInfoComponent() {
    const history = useHistory();
    const [openSystemInfoDialog, setOpenSystemInfoDialog] = useState(false);
    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    useEffect(() => {
        // noinspection ChainedFunctionCallJS
        let systemHasErrors = Object.keys(systemInfo).some(key => !systemInfo[key]);
        setOpenSystemInfoDialog(systemHasErrors);

    }, [systemInfo]);

    const handleCloudConnection = function handleCloudConnectionSystemError() {
        setOpenSystemInfoDialog(false);
    };

    if (!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet) {
        // Automatically redirect to the create form
        history.push(
            `${routes.settings.path}${routes.cloudConnections.path}${routes.createCloudConnection.path}?provider=${systemInfo.cloudProvider}&name=Default%20Storage`);
    }

    return (
        <>
            {!isEmptyObject(systemInfo) && !systemInfo.isDefaultCloudConnectionSet &&
            <Dialog open={openSystemInfoDialog} onClose={() => setOpenSystemInfoDialog(false)}>

                <DialogTitle>Configure Default Storage</DialogTitle>


                <DialogContent>
                    <DialogContentText>
                        SFTP Gateway requires a Cloud Connection for SFTP Users to store and retrieve files.
                        Use the underlying form to create a cloud connection that will act as the default storage
                        location.
                        <CircularProgressButton onClick={handleCloudConnection} buttonTextTransform='none'
                                                label='OK'/>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
            }
        </>
    );
}

SystemInfoComponent.propTypes = {};

SystemInfoComponent.defaultProps = {};

export default SystemInfoComponent;
