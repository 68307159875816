import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {
    makeStyles, Link, Grid, Box, Menu, Breadcrumbs, useTheme,
    Typography, IconButton, Card, Hidden, MenuItem, Input
} from '@material-ui/core';
import {MoreVert as MoreVertIcon, Refresh as RefreshIcon, Search as SearchIcon} from '@material-ui/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import {StyledNavControls} from '../common/styled';
import ConfirmDialog from '../common/ConfirmDialog';
import CircularProgressButton from '../common/CircularProgressButton';
import FoldersTable from './FoldersTable';

export const StyledRowIcon = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 0.85rem;
`;

const useStyles = makeStyles((theme) => ({
    root: {padding: theme.spacing(3)},
    iconButton: {padding: theme.spacing(1)},
    card: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        overflow: 'inherit',
    },
}));

// noinspection FunctionNamingConventionJS
function FolderListComponent(props) {
    const history = useHistory();
    const theme = useTheme();
    const classes = useStyles();
    const {folder, searchBox, setSearchBox} = {...props};

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const expandFolderActions = function expandFolderActions(event) {
        setAnchorEl(event.currentTarget);
    };
    const closeFolderActions = function closeFolderActions() {
        setAnchorEl(null);
    };

    const handleBreadcrumbClick = function handleFolderBreadcrumbClick(event, folderId) {
        event.preventDefault();
        history.push(`${routes.folders.path}/${folderId}`);
    };

    const displayAbsolutePath = function displayAbsolutePathOnFoldersList(folder) {
        const folderNames = folder?.absolutePath?.split('/');
        const folderIds = folder?.ltreePath?.split('.');
        let foldersArray = folderIds?.map(function (id, index) {
            return {id, name: folderNames[index]}
        });

        // noinspection ConditionalExpressionJS
        return (
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap' spacing={4}>
                <Grid item style={{flex: '1 1 auto'}}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        {foldersArray?.map((item) => (
                            <Link key={item.id} color='inherit' href={`${routes.folders.path}/${item.id}`}
                                  onClick={(event) => handleBreadcrumbClick(event, item.id)}>
                                {isEmpty(item.name) ? <FontAwesomeIcon icon={faFolderOpen}/> : item.name}
                            </Link>
                        ))};
                    </Breadcrumbs>
                </Grid>
                <Grid item style={{flex: '0 0 auto'}}>
                    <Grid container wrap='nowrap'>
                        <Hidden xsDown>
                            <CircularProgressButton label='Edit Folder' size='small' buttonTextTransform='none'
                                                    fullWidth={false}
                                                    mr={1} mt={0}
                                                    onClick={() => history.push(`${routes.folders.path}/${folder.id}${routes.updateFolder.path}`)}/>
                            <CircularProgressButton label='Add Folder' size='small' buttonTextTransform='none'
                                                    fullWidth={false}
                                                    mt={0} mr={1}
                                                    onClick={() => history.push(`${routes.folders.path}/${folder.id}${routes.createFolder.path}`)}/>
                        </Hidden>
                        <IconButton aria-label='refresh-folder-list' size='small' style={{width: '30px'}}
                                    onClick={props.refresh}>
                            <RefreshIcon fontSize='small'/>
                        </IconButton>
                        <IconButton theme={theme} size='small' style={{width: '30px'}}
                                    aria-controls='folder-sub-menu'
                                    aria-haspopup='true'
                                    onClick={expandFolderActions}>
                            <MoreVertIcon fontSize='small'/>
                        </IconButton>
                        <Menu id='folder-sub-menu' anchorEl={anchorEl} keepMounted getContentAnchorEl={null}
                              open={Boolean(anchorEl)} onClose={closeFolderActions}
                              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                              transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        >
                            <Box display={{sm: 'none'}}>
                                <StyledMenuItem onClick={() =>
                                    history.push(`${routes.folders.path}/${folder.id}${routes.updateFolder.path}`)}>
                                    Edit Folder
                                </StyledMenuItem>
                                <StyledMenuItem onClick={() =>
                                    history.push(`${routes.folders.path}/${folder.id}${routes.createFolder.path}`)}>
                                    Add Folder
                                </StyledMenuItem>
                            </Box>
                            <StyledMenuItem onClick={() => setShowDeleteConfirmDialog(true)}>
                                Delete Folder
                            </StyledMenuItem>
                            <ConfirmDialog
                                title='Delete Folder'
                                open={showDeleteConfirmDialog}
                                setOpen={setShowDeleteConfirmDialog}
                                onConfirm={() => props.deleteFolder(folder)}
                            >
                                Are you sure you want to delete {folder.name}?
                            </ConfirmDialog>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        )
    };

    const goToParentFolder = function goToParentFolder(folder) {
        const folderIds = folder?.ltreePath?.split('.') || [];
        let parentId = folderIds[folderIds.length - 2];
        if (isEmpty(parentId)) {
            parentId = 0;
        }
        history.push(`${routes.folders.path}/${parentId}`);
    }

    const goToSubFolder = function goToSubFolder(subFolder) {
        setSearchBox('');
        history.push(`${routes.folders.path}/${subFolder.id}`);
    };

    return (<div className={classes.root}>

        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item>
                <Typography variant='h5'>{routes.folders.pageTitle}</Typography>
            </Grid>
            <Grid item>
                    <IconButton type='submit' className={classes.iconButton} aria-label='search folders'>
                        <SearchIcon/>
                    </IconButton>
                    <Input
                        value={searchBox}
                        onChange={(e) => setSearchBox(e.target.value)}
                        placeholder='Search folders'
                        inputProps={{'aria-label': 'search folders'}}
                    />
            </Grid>
        </Grid>

        <Card className={classes.card} variant='outlined'>
            <StyledNavControls theme={theme}>
                {displayAbsolutePath(folder)}
            </StyledNavControls>
            <FoldersTable folder={folder} goToParentFolder={goToParentFolder} openFolder={(item) => goToSubFolder(item)}/>
        </Card>
    </div>);
}

const Folder = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FolderListComponent.propTypes = {
    folder: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        absolutePath: PropTypes.string,
        ltreePath: PropTypes.string,
        folders: PropTypes.arrayOf(Folder),
    }),
    loadingFolders: PropTypes.bool,
    refresh: PropTypes.func,
    deleteFolder: PropTypes.func,
    searchBox: PropTypes.string,
    setSearchBox: PropTypes.func,
};

FolderListComponent.defaultProps = {
    folder: {
        id: '',
        name: '',
        absolutePath: '',
        folders: [],
    },
};

export default FolderListComponent;
