import React from 'react';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';

// noinspection FunctionNamingConventionJS
export default function CustomDeleteIcon(props) {
    return (
        <DeleteIcon style={{color: red['800']}} {...props}/>
    );
}
