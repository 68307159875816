import React, {useEffect, useState} from 'react';
import toast from 'toasted-notes';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import ProgressFormCard from '../common/ProgressFormCard';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import AwsCloudConnectionEditForm from './AwsCloudConnectionEditForm';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject, handleErrors} from '../../util/errorHandler';
import {authenticationService} from '../../container/auth/authenticationService';
import {setAuthenticated} from '../../action';
import AzureCloudConnectionEditForm from "./AzureCloudConnectionEditForm";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

// noinspection FunctionNamingConventionJS
function CloudConnectionUpdateComponent(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [isBlocking, setIsBlocking] = useState(false);
    const [cloudConnection, setCloudConnection] = useState(props.cloudConnection);
    const [errors, setErrors] = useState({});

    const provider = cloudConnection.cloudProvider;
    const providerTitle = (provider === 'aws') ? 'AWS' : 'Azure'
    // Update the state from updated props.
    useEffect(() => {
        setCloudConnection(props.cloudConnection);
    }, [props.cloudConnection]);

    const handleTextChange = function handleTextChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value});
    };

    const handleInstanceCredentialsChange = function handleInstanceCredentialsChangeOnCloudConnectionForm(event) {
        setIsBlocking(true);
        setCloudConnection({...cloudConnection, [event.target.name]: event.target.value === "true"});
    };

    const onSubmit = async function updateCloudConnection(event, data) {
        event.preventDefault();
        try {
            await props.updateCloudConnection(data);
        } catch(error) {
            if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                const tempErrors = handleErrors(error.response);
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                authenticationService.clearTokenInfo();
                dispatch(setAuthenticated(false));
            } else {
                let tempErrors = convertErrorsToObject(error.response);
                setErrors({...errors, ...tempErrors});
            }
        }
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.settings.path);
            }
        } else {
            history.push(routes.settings.path);
        }
    };

    // noinspection ConditionalExpressionJS
    return (
        <div className={classes.root}>
            <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                    <StyledBreadcrumbIcon theme={theme}/>
                    Back to settings
                </StyledBreadcrumbLink>
            </StyledBreadcrumbs>
            <ProgressFormCard loadingContent={props.loadingCloudConnection}
                              header={
                                  <CustomCardHeader
                                      title={routes.updateCloudConnection.pageTitle(providerTitle)}
                                      titleAlign='center'
                                  />
                              }
                              content={<>
                                  {provider === 'aws' &&
                                  <AwsCloudConnectionEditForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                              id={cloudConnection.id} name={cloudConnection.name}
                                                              notes={cloudConnection.notes}
                                                              basePrefix={cloudConnection.basePrefix}
                                                              region={cloudConnection.region}
                                                              encryptionType={cloudConnection.encryptionType}
                                                              setEncryptionType={handleTextChange}
                                                              encryptionId={cloudConnection.encryptionId}
                                                              useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                              setInstanceCredentials={handleInstanceCredentialsChange}
                                                              keyId={cloudConnection.accessKeyId}
                                                              keySecret={cloudConnection.accessSecret}
                                                              accessSecretSet={cloudConnection.accessSecretSet}
                                                              connectivity={cloudConnection.connectivity}
                                                              errors={errors}
                                  />}
                                  {provider === 'azure' &&
                                  <AzureCloudConnectionEditForm handleSubmit={onSubmit} handleCancel={handleCancel}
                                                                id={cloudConnection.id} name={cloudConnection.name}
                                                                notes={cloudConnection.notes}
                                                                basePrefix={cloudConnection.basePrefix}
                                                                region={cloudConnection.region}
                                                                useInstanceCredentials={cloudConnection.useInstanceCredentials}
                                                                setInstanceCredentials={handleInstanceCredentialsChange}
                                                                accountName={cloudConnection.accountName}
                                                                connectionString={cloudConnection.connectionString}
                                                                connectionStringSet={cloudConnection.connectionStringSet}
                                                                connectivity={cloudConnection.connectivity}
                                                                errors={errors}
                                  />}
                              </>
                              }
            />
        </div>
    );
}

CloudConnectionUpdateComponent.propTypes = {
    cloudConnection: PropTypes.object,
    updateCloudConnection: PropTypes.func,
    loadingCloudConnection: PropTypes.bool,
};

CloudConnectionUpdateComponent.defaultProps = {};

export default CloudConnectionUpdateComponent;
